import http from "../services/http";
import _ from "lodash";

const DOMAIN = process.env.REACT_APP_DOMAIN;

const API_URL = `${DOMAIN}/v1`;
const API_URL_V2 = `${DOMAIN}/v2`;
const API_URL_V4 = `${DOMAIN}/v4`;
const API_URL_TEAM = `${DOMAIN}/team`;

export const tryCall = async (f, onCatch = () => {}) => {
  let count = 0;
  let trySend = true;
  const max = 5;
  while (trySend) {
    try {
      const result = await f();
      trySend = false;
      return Promise.resolve(result);
    } catch (e) {
      console.error(e);
      if (++count === max) {
        onCatch(e);
        return Promise.resolve(null);
      }
    }
  }
};

export const requestProfile = async () => {
  const url = `${API_URL_V2}/me`;
  const data = await http.sendGet(url);
  return data;
};

export const searchUsers = (q = '', params = {}) => {
  let url = `${API_URL_TEAM}/users/search?q=${encodeURIComponent(q)}`;
  if (params.include_candidate) {
    url += `&include_candidate=${params.include_candidate}`;
  }
  return http.sendGet(url);
};

export const getProfilAction = (viewer_id, viewed_id) => {
  let url = `${API_URL_TEAM}/users/profile_actions`;
  return http.sendPost({ url, data: {viewer_id, viewed_id} });
};

export const validateFieldsUrl = `${API_URL_TEAM}/users/validate_fields`;
export const validateFields = (viewed_id) => {
  let url = `${API_URL_TEAM}/users/validate_fields`;
  return http.sendPost({ url, data: {viewed_id} });
};

const USERS_CACHE = {};

export const requestUserProfile = async (id) => {
  if (USERS_CACHE[String(id)]) {
    return USERS_CACHE[String(id)];
  }
  const url = `${API_URL_V2}/profile?id=${id}`;
  const data = await http.sendGet(url);
  USERS_CACHE[String(id)] = data;
  return data;
};

export const requestSundaySerendipities = async (page = 1) => {
  const url = `${API_URL_TEAM}/sunday_serendipities?page=${page}`;
  const data = await http.sendGet(url);
  return data;
};

export const requestStatistics = async () => {
  const url = `${API_URL_TEAM}/statistics`;
  const data = await http.sendGet(url);
  return data;
};

export const sycnMailchimp = () => {
  const url = `${API_URL_TEAM}/mailchimp/sync`;
  return http.sendPost({ url, data: {} });
};

export const setUserLocale = () => {
  const url = `${API_URL_V2}/set_locale`;
  const locale = navigator.language.split("-")[0];
  return http.sendPost({ url, data: { locale } });
};

export const requestDictionary = () => {
  const url = `${API_URL}/dictionary`;
  return http.sendGet(url);
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
export const registerUser = async (data) => {
  const url = `${API_URL}/register`;
  return http.sendPost({ url, data: { ...data, timezone } });
};

export const startModeration = () => {
  const url = `${API_URL_TEAM}/moderation`;
  return http.sendGet(url);
};

export const pauseModeration = (data) => {
  const url = `${API_URL_TEAM}/moderation/pause`;
  return http.sendPost({ url, data });
};

export const declineUser = (data) => {
  const url = `${API_URL_TEAM}/moderation/decline`;
  return http.sendPost({ url, data });
};

export const inviteUser = (data) => {
  const url = `${API_URL_TEAM}/moderation/invite`;
  return http.sendPost({ url, data });
};

export const sendSuggestions = (data) => {
  const url = `${API_URL_TEAM}/moderation/send_suggestions`;
  return http.sendPost({ url, data });
};

export const requestLogin = async (email) => {
  const url = `${API_URL_TEAM}/sessions`;

  return http.sendPost({ url, data: { email } });
};

export const loginUser = async (token) => {
  const url = `${API_URL_TEAM}/sessions/login`;

  return http.sendPost({ url, data: { token } });
};

export const logoutUser = async () => {
  const url = `${API_URL_TEAM}/sessions`;

  return http.sendDelete({ url, data: {} });
};

export const resetPassword = async (data) => {
  const url = `${API_URL_V2}/reset_password`;

  return http.sendPost({ url, data });
};
export const setPassword = async (data) => {
  const url = `${API_URL_V2}/set_password`;

  return http.sendPost({ url, data });
};
export const checkPassword = async (data) => {
  const url = `${API_URL_V2}/check_password`;

  return http.sendPost({ url, data });
};
export const updateEmail = async (data) => {
  const url = `${API_URL_V2}/profile/update_email`;

  return http.sendPost({ url, data });
};

export const searchCity = (q, geocoder_result) => {
  const url = `${API_URL_V2}/cities/search`;
  return http.sendPost({
    url,
    data: { q, geocoder_result },
  });
};

// Lists

export const listsSearchCity = (q) => {
  const url = `${API_URL_TEAM}/lists/cities`;
  return http.sendPost({
    url,
    data: { q },
  });
};

export const cityUserPlaces = (city_id) => {
  const url = `${API_URL_TEAM}/lists/user_places`;
  return http.sendPost({
    url,
    data: { city_id },
  });
};

export const fetchLists = async () => {
  const url = `${API_URL_TEAM}/lists`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchList = async (id) => {
  const url = `${API_URL_TEAM}/lists/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createList = async (data) => {
  const url = `${API_URL_TEAM}/lists`;
  const form = new FormData();
  setForm("list", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updateList = async (id, data) => {
  const url = `${API_URL_TEAM}/lists/${id}`;

  const form = new FormData();
  setForm("list", form, data);

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const destroyList = async (id) => {
  const url = `${API_URL_TEAM}/lists/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

// Places

export const fetchTags = async (city_id) => {
  const url = `${API_URL_TEAM}/places/tags?city_id=${city_id}`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchPlace = async (id) => {
  const url = `${API_URL_TEAM}/places/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const destroyPlace = async (id) => {
  const url = `${API_URL_TEAM}/places/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

const setForm = (root, form, data) => {
  for (const key in data) {
    if (Array.isArray(data[key])) {
      data[key].forEach((element, i) => {
        if (_.isObject(element)) {
          for (const ikey in element) {
            if (
              key === "images_attributes" ||
              key === "invitation_codes_attributes"
            ) {
              form.append(`${root}[${key}][${i}][${ikey}]`, element[ikey]);
            } else {
              form.append(`${root}[${key}][][${ikey}]`, element[ikey]);
            }
          }
        } else {
          form.append(`${root}[${key}][]`, element);
        }
      });
    } else if (_.isObject(data[key])) {
      if (key === "photo") {
        form.append(`${root}[${key}]`, data[key]);
      } else {
        for (const ikey in data[key]) {
          form.append(`${root}[${key}][${ikey}]`, data[key][ikey]);
        }
      }
    } else {
      form.append(`${root}[${key}]`, data[key]);
    }
  }
};

export const updatePlace = async (id, data) => {
  const url = `${API_URL_TEAM}/places/${id}`;

  const form = new FormData();

  setForm("place", form, data);

  return http.sendPut({ url, data: form, formData: true });
};

export const createPlace = async (data) => {
  const url = `${API_URL_TEAM}/places`;

  const form = new FormData();

  setForm("place", form, data);

  return http.sendPost({ url, data: form, formData: true });
};

const KEY = "AIzaSyAFPNcZAB__VPC399ah72GLMaQk-sr9RFc";
const supplant = (s, o) => {
  return s.replace(/{([^{}]*)}/g, (a, b) => {
    var r = o[b];
    return typeof r === "string" || typeof r === "number" ? r : a;
  });
};

export const findCity = (lat, lng) => {
  const url = `${API_URL_V2}/cities/find_city`;
  return http.sendPost({
    url,
    data: { lat, lng },
  });
};

let placeService;

export const getPlace = async (place) => {
  if (!placeService) {
    placeService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }
  return new Promise((rs, rj) => {
    if (!place.place_id) {
      rs(null);
      return;
    }
    const request = {
      placeId: place.place_id,
      fields: [
        "name",
        "rating",
        "address_component",
        "opening_hours",
        "place_id",
        "url",
        "formatted_address",
        "geometry",
      ],
    };
    placeService.getDetails(request, (place, status) => {
      if (status == window.google.maps.places.PlacesServiceStatus.OK) {
        rs(place);
      } else {
        rs(null);
      }
    });
  });
};

export const findPlaceInGoogle = async (q) => {
  const url = `${API_URL_TEAM}/places/google_places?q=${q}`;
  const data = await http.sendGet(url);
  return data;
};

export const ogPlacePreview = async (data) => {
  const url = `${API_URL_TEAM}/places/og_preview`;

  const form = new FormData();

  setForm("place", form, data);

  return http.sendPost({ url, data: form, formData: true, responseAsBlob: true });
};

export const findPlaceInCity = async (q) => {
  return new Promise((resolve, reject) => {
    const service = new window.google.maps.places.AutocompleteService();
    service.getQueryPredictions(
      { input: q, types: "establishment" },
      async (r, s) => {
        if (s != window.google.maps.places.PlacesServiceStatus.OK || !r) {
          return [];
        }
        const promises = r.map(async (place) => {
          return await getPlace(place);
        });
        const places = await Promise.all(promises);
        resolve(places.filter((p) => p));
      }
    );
  });
};

// Episodes

export const fetchEpisodes = async () => {
  const url = `${API_URL_TEAM}/episodes`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchEpisode = async (id) => {
  const url = `${API_URL_TEAM}/episodes/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createEpisode = async (data) => {
  const url = `${API_URL_TEAM}/episodes`;
  const form = new FormData();
  setForm("episode", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updateEpisode = async (id, data) => {
  const url = `${API_URL_TEAM}/episodes/${id}`;

  const form = new FormData();
  setForm("episode", form, data);

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const destroyEpisode = async (id) => {
  const url = `${API_URL_TEAM}/episodes/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

export const fetchEpisodeTags = async () => {
  const url = `${API_URL_TEAM}/episodes/tags`;
  const data = await http.sendGet(url);
  return data;
};

// Talks

export const fetchTalks = async () => {
  const url = `${API_URL_TEAM}/talks`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchTalk = async (id) => {
  const url = `${API_URL_TEAM}/talks/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createTalk = async (data) => {
  const url = `${API_URL_TEAM}/talks`;
  const form = new FormData();
  setForm("talk", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updateTalk = async (id, data) => {
  const url = `${API_URL_TEAM}/talks/${id}`;

  const form = new FormData();
  setForm("talk", form, data);

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const destroyTalk = async (id) => {
  const url = `${API_URL_TEAM}/talks/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

// Email Templates

export const fetchTemplates = async () => {
  const url = `${API_URL_TEAM}/email_templates`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchTemplate = async (id) => {
  const url = `${API_URL_TEAM}/email_templates/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createTemplate = async (data) => {
  const url = `${API_URL_TEAM}/email_templates`;
  const form = new FormData();
  setForm("email", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const testTemplate = async (data) => {
  const url = `${API_URL_TEAM}/email_templates/test`;
  const form = new FormData();
  setForm("email", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updateTemplate = async (id, data) => {
  const url = `${API_URL_TEAM}/email_templates/${id}`;

  const form = new FormData();
  setForm("email", form, data);

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const destroyTemplate = async (id) => {
  const url = `${API_URL_TEAM}/email_templates/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

// Push Templates

export const fetchPushTemplates = async () => {
  const url = `${API_URL_TEAM}/push_templates`;
  const data = await http.sendGet(url);
  return data;
};

export const fetchPushTemplate = async (id) => {
  const url = `${API_URL_TEAM}/push_templates/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createPushTemplate = async (data) => {
  const url = `${API_URL_TEAM}/push_templates`;
  const form = new FormData();
  setForm("push", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const testPushTemplate = async (data) => {
  const url = `${API_URL_TEAM}/push_templates/test`;
  const form = new FormData();
  setForm("push", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updatePushTemplate = async (id, data) => {
  const url = `${API_URL_TEAM}/push_templates/${id}`;

  const form = new FormData();
  // setForm("templates", form, data);
  for (const i in data) {
    const key = data[i].id;
    const row = data[i];
    for (const v in row) {
      form.append(`templates[${key}][${v}]`, row[v]);
    }
  }

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const destroyPushTemplate = async (id) => {
  const url = `${API_URL_TEAM}/push_templates/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

// Communities

export const fetchCommunities = async (id) => {
  const url = `${API_URL_TEAM}/communities`;
  const data = await http.sendGet(url);
  return data;
};

export const destroyCommunity = async (id) => {
  const url = `${API_URL_TEAM}/communities/${id}`;
  const data = await http.sendDelete({ url });
  return data;
};

export const fetchCommunity = async (id) => {
  const url = `${API_URL_TEAM}/communities/${id}`;
  const data = await http.sendGet(url);
  return data;
};

export const createCommunity = async (data) => {
  const url = `${API_URL_TEAM}/communities`;
  const form = new FormData();
  setForm("community", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const updateCommunity = async (id, data) => {
  const url = `${API_URL_TEAM}/communities/${id}`;

  const form = new FormData();

  setForm("community", form, data);

  return http.sendPut({ url, data: form, formData: true });
};

export const fetchCommunityCategories = async () => {
  const url = `${API_URL_TEAM}/communities/tags`;
  const data = await http.sendGet(url);
  return data;
};

export const communitiesSearchCity = (q) => {
  const url = `${API_URL_TEAM}/communities/cities`;
  return http.sendPost({
    url,
    data: { q },
  });
};

export const communitiesCheckCode = (code) => {
  const url = `${API_URL_TEAM}/communities/check_code`;
  return http.sendPost({
    url,
    data: { code },
  });
};

export const deleteCommunityCode = (id) => {
  const url = `${API_URL_TEAM}/communities/delete_code`;
  return http.sendPost({
    url,
    data: { id },
  });
};



// Prompt Templates

export const fetchPromptTemplates = async () => {
  const url = `${API_URL_TEAM}/prompt_templates`;
  const data = await http.sendGet(url);
  return data;
  
};

export const fetchPromptTemplate = async (id) => {
  const url = `${API_URL_TEAM}/prompt_templates/${id}`;
  const data = await http.sendGet(url);
  return data;
};


export const updatePromptTemplate = async (id, data) => {
  const url = `${API_URL_TEAM}/prompt_templates/${id}`;

  const form = new FormData();
  setForm("prompt_template", form, data);

  return http.sendPut({
    url,
    data: form,
    formData: true,
  });
};

export const testPromptTemplate = async (data) => {
  const url = `${API_URL_TEAM}/prompt_templates/test`;
  const form = new FormData();
  setForm("prompt_template", form, data);
  return http.sendPost({ url, data: form, formData: true });
};

export const fetchPublicities = async () => {
  const url = `${API_URL_V4}/profiles/publicity`;
  const data = await http.sendGet(url);
  return data;
};